export class RepeatAnimationClass {
	constructor(delay, triggerDelay) {
		this.disableTimeout = null;
		this.repeatTimeout = null;
		this.triggerTimeout = null;
		this.delay = delay;
		this.triggerDelay = triggerDelay;
	}

	trigger(
		bool,
		callback,
		delay = this.delay,
		triggerDelay = this.triggerDelay,
	) {
		if (this.triggerTimeout) {
			clearTimeout(this.triggerTimeout);
		}
		if (this.disableTimeout) {
			clearTimeout(this.disableTimeout);
		}
		if (this.repeatTimeout) {
			clearTimeout(this.repeatTimeout);
		}
		this.triggerTimeout = setTimeout(() => {
			if (bool) {
				callback(false);
				this.repeatTimeout = setTimeout(() => {
					callback(true);
					this.disableTimeout = setTimeout(() => callback(false), delay);
				}, 100);
				return;
			}
			callback(true);
			this.disableTimeout = setTimeout(() => callback(false), delay);
		}, triggerDelay || 0);
	}
}

import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnMount } from '@common/hooks/useOnMount';
import swoosh from '../../../../../public/img/dice/switch-swoosh.json';
import { LottieWrapper } from '../../LottieWrapper';
import { LottieIds } from '../../LottieWrapper/Lottie.ids';

export const SWOOSH_DURATION = 500;

export const SwitchSwoosh: FC = () => {
	const timeoutRef = useRef(null);
	const [finished, setFinished] = useState<boolean>(false);

	useOnMount(() => {
		if (timeoutRef.current) {
			return;
		}
		timeoutRef.current = setTimeout(() => {
			setFinished(true);
		}, SWOOSH_DURATION);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	});

	if (finished) {
		return null;
	}

	return (
		<StyledSwitchSwoosh>
			<LottieWrapper dataname={LottieIds.SWOOSH} json={swoosh} />
		</StyledSwitchSwoosh>
	);
};

export const StyledSwitchSwoosh = styled.div`
	height: 100%;
	position: absolute;
	width: 225px;
`;

import React, { FC } from 'react';
import { StyledSliderTick, StyledSliderTickValue } from './Slider.styled';

const WIDTH = 5;

export interface TickProps {
	value: number;
	percent: number;
}

export const Tick: FC<TickProps> = ({ value, percent }) => {
	return (
		<div>
			<StyledSliderTickValue
				style={{
					width: `${WIDTH}%`,
					left: `${percent}%`,
					marginLeft: `${-WIDTH / 2}%`,
				}}
			>
				{value}
			</StyledSliderTickValue>
			<StyledSliderTick
				style={{
					left: `${percent}%`,
				}}
			/>
		</div>
	);
};

import React, { FC } from 'react';
import { TracksObject } from 'react-compound-slider/dist/types/Tracks/types';
import { StyledSliderTrack } from './Slider.styled';

export interface TrackProps {
	sourcePercent: number;
	targetPercent: number;
	sliderMargin: number;
	getTrackProps: TracksObject['getTrackProps'];
}

export const Track: FC<TrackProps> = ({
	sourcePercent,
	targetPercent,
	sliderMargin,
	getTrackProps,
}) => {
	return (
		<StyledSliderTrack
			style={{
				left: `${sourcePercent}%`,
				width: `${targetPercent - sourcePercent + sliderMargin}%`,
			}}
			{...getTrackProps()} // this will set up events if you want it to be clickable (optional)
		/>
	);
};

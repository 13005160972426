import styled, { css } from 'styled-components';

export const StyledLottie = styled.div<{
	$height?: number;
	$width?: number;
}>`
	pointer-events: none;

	svg {
		${(props) =>
			props.$height
				? css`
						height: ${props.$height}px!important;
				  `
				: ''}
		${(props) =>
			props.$width
				? css`
						height: ${props.$width}px!important;
				  `
				: ''}
	}
`;

import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import ReactCompoundSlider from 'react-compound-slider';
import { borderRadius } from '@legacyApp/client/modules/style/defaults';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';
import { Sounds } from '@common/constants/config/sounds';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { parseImageSrc } from '../../default/Image/Image';
import audioService from '../../../../LegacyApp/client/modules/app/audioService';
import { SwitchSwoosh } from './SwitchSwoosh';

export interface HandleProps {
	percent: number;
	id: string;
	getHandleProps: ReactCompoundSlider.HandlesObject['getHandleProps'];
	animation?: boolean;
	hideValue?: boolean;
	value: number;
}

export const Handle: FC<HandleProps> = ({
	percent,
	id,
	getHandleProps,
	animation,
	hideValue,
	value,
}) => {
	const onMouseEnter = useCallback(() => {
		if (hideValue) {
			audioService.play(Sounds['handle-hover'], false, 0.08);
		}
	}, [hideValue]);

	return (
		<StyledSliderHandleWrapper
			data-id={id}
			style={{
				transform: `translateX(${percent}%)`,
			}}
			{...getHandleProps(id)}
		>
			{animation && <SwitchSwoosh />}
			<StyledSliderHandle onMouseEnter={onMouseEnter} $active={!hideValue}>
				<StyledSliderHandleValue
					className="text-style-md-semibold"
					$hide={hideValue}
				>
					{roundNumber(value, 2)}
				</StyledSliderHandleValue>
			</StyledSliderHandle>
		</StyledSliderHandleWrapper>
	);
};

export const glowBackgroundColor = '#00ce19';
export const glowShadowColor = '#0ce827';
export const glowBorderColor = '#0aef26';

const sliderHandleGlow = css`
	background-color: ${glowBackgroundColor}!important;
	border: 3px solid ${glowBorderColor};
	box-shadow: 0 0 35px ${glowShadowColor}!important;
`;

export const StyledSliderHandle = styled.div<{
	$active?: boolean;
}>`
	align-items: center;
	background-color: ${(props) => props.theme.colors.positive};
	border: 3px solid transparent;
	box-shadow: 0 0 9px 0 ${rgba('#0c4112', 0.7)};
	${borderRadius};
	color: ${(props) => props.theme.colors.text};
	cursor: pointer;
	display: flex;
	justify-content: center;
	outline: none;
	pointer-events: all;
	text-align: center;
	transition: ${(props) => props.theme.layout.transition};

	${(props) => (props.$active ? sliderHandleGlow : '')}
	&:hover {
		${sliderHandleGlow}
	}

	&:before {
		background-image: url(${parseImageSrc('/img/icons/grip-lines.svg')});
		background-position: center;
		background-repeat: no-repeat;
		content: '';
		display: inline-block;
		height: 18px;
		left: 50%;
		position: relative;
		transform: translateX(-50%);
		width: 100%;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		background-color: ${(props) => props.theme.colors.positiveLight};
	}
`;
export const StyledSliderHandleWrapper = styled.div`
	border: 0;
	margin-left: -22.5px;
	pointer-events: none;
	position: absolute;
	width: 100%;
	z-index: 2;
`;

export const StyledSliderHandleValue = styled.div<{
	$hide?: boolean;
}>`
	line-height: 2;
	${borderRadius};
	opacity: ${(props) => (props.$hide ? '0' : '1')};
	position: absolute;
	${(props) =>
		fontSize({
			sizeBigScreen: props.theme.text.size.bigScreen,
			important: true,
		})}
	${sliderHandleGlow}
	&:after {
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid ${glowBorderColor};
		bottom: -10px;
		box-shadow: none;
		content: '';
		display: block;
		height: 0;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 0;
	}
`;

export const LottieIds = {
	GAME_RESULT_STAR_1: 'game-result-star-1',
	GAME_RESULT_STAR_2: 'game-result-star-2',
	FLASH_BET_ANIMATION: 'flash-bet-animation',
	RAKE_BACK: 'rake-back',
	VIP_ICON: 'vip-icon',
	SWOOSH: 'swoosh',
	BONUS_CODE_CLAIM: 'bonus-code-claim',
} as const;

type LottieIdsKeys = keyof typeof LottieIds;
export type LottieIdsType = (typeof LottieIds)[LottieIdsKeys];

export const getChangedValueIndexFromArray = (
	prev: ReadonlyArray<number | string>,
	next: ReadonlyArray<number | string>,
): ReadonlyArray<{ value: number | string; index: number }> => {
	return prev
		.map((value, index) => ({ value, index }))
		.filter((element) => {
			return Number(element.value) !== Number(next[element.index]);
		});
};

import styled, { css, keyframes } from 'styled-components';
import { Slider } from 'react-compound-slider';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { StyledSwitchSwoosh } from './SwitchSwoosh';
import {
	StyledSliderHandle,
	StyledSliderHandleValue,
	StyledSliderHandleWrapper,
} from './handle';
import { SliderRangeMode } from './slider';

const HandleRotate = (props) => keyframes`
    0% {
      transform: rotateY(${props.$opposite ? 180 : 0}deg) scale(1);
    }
    50% {
      transform: rotateY(90deg) scale(1.1);
    }
    100% {
      transform: rotateY(${props.$opposite ? 0 : 180}deg) scale(1);
    }
`;

const BarSwitchAnimation = (props) => keyframes`
		0% {
			background-color: #00FF1D;
    }
    50% {
      background-color: #00FF1D;
    }
    100% {
      background-color: ${props.theme.colors.positive};
    }
`;

const BarSwitchSlideAnimation = (props) => keyframes`
		0% {
			left: ${props.$opposite ? '0' : `${props.$handlePosition}%`};
    }
    99% {
    	opacity: 1;
    }
    100% {
      left: ${props.$opposite ? '100%' : '-20px'};
      opacity: 0;
    }
`;

export const StyledSliderRail = styled.div`
	border-radius: 5px;
	overflow: hidden;
	position: absolute;
	width: 100%;
`;

export const StyledSliderTick = styled.div`
	background-color: #272f3c;
	margin-left: -0.5px;
	position: absolute;
	width: 1px;

	&:after {
		background-color: #6b778d;
		content: '';
		display: block;
		width: 1px;
		z-index: 1;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		background-color: #b3bfd3;
		margin-top: 30px;

		&:after {
			height: 10px;
			background-color: #e3e3e3;
		}
	}
`;

export const StyledSliderTickValue = styled.div.attrs({
	className: 'text-style-md-semibold',
})`
	color: ${(props) => props.theme.colors.secondText};
	position: absolute;
	text-align: center;

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		color: ${(props) => props.theme.colors.text};
	}
`;

export const StyledSliderTicks = styled.div`
	overflow: hidden;
`;

export const StyledSliderTrack = styled.div`
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
`;

export const SliderStyles = () => {
	const tickValueMarginTop = 5;
	const tickValueLineHeight = 1.3;
	const trackHeight = {
		bigScreen: 10,
		default: 8,
	};
	const tickSpaceTop = {
		bigScreen: 10,
		desktop: 5,
		tablet: 5,
		height550: 5,
	};
	const tickSpaceBottom = {
		bigScreen: 15,
		desktop: 10,
		tablet: 10,
		height550: 10,
	};
	const tickHeight = {
		bigScreen: 40,
		desktop: 30,
		height550: 20,
	};
	const handleSize = {
		bigScreen: 67,
		desktop: 50,
		tablet: 45,
		height550: 35,
	};
	const handleValueSize = {
		bigScreen: 42 + 7 + 3,
		desktop: 38 + 7 + 3,
		tablet: 30 + 7 + 3,
	};
	const getTickMarginTop = (fontSize, space) =>
		calcStyle({
			a: space + tickValueMarginTop,
			b: calcStyle({
				a: fontSize,
				b: tickValueLineHeight,
				mode: '*',
			}),
		});
	const getHandleMarginTop = (trackMarginTop, trackSize, handleSize) =>
		calcStyle({
			a: trackMarginTop,
			b: (handleSize - trackSize) / 2,
			mode: '-',
		});
	const getDefault = (
		props,
		font,
		tickHeight,
		tickSpaceTop,
		tickSpaceBottom,
		handleSize,
		trackHeight,
		handleValueTop,
	) => {
		const tickMarginTop = getTickMarginTop(font, tickSpaceTop);
		const trackMarginTop = calcStyle({
			a: tickMarginTop,
			b: tickHeight + tickSpaceBottom,
		});
		const handleMarginTop = getHandleMarginTop(
			trackMarginTop,
			trackHeight,
			handleSize,
		);
		const sliderHeight = calcStyle({
			a: handleMarginTop,
			b: handleSize,
		});
		return css`
			height: ${sliderHeight};

			${StyledSliderTick} {
				height: ${tickHeight}px;
				margin-top: ${tickMarginTop};
			}

			${StyledSliderTrack},
			${StyledSliderRail} {
				margin-top: ${trackMarginTop};
			}

			${StyledSliderHandleWrapper} {
				margin-top: ${handleMarginTop};
				margin-left: -${handleSize / 2}px;
			}

			${StyledSliderHandle} {
				width: ${handleSize}px;
				height: ${handleSize}px;
			}

			${StyledSliderHandleValue} {
				top: -${handleValueTop}px;
				width: ${handleSize + 4}px;
			}

			${StyledSwitchSwoosh} {
				/* native width of swoosh: 225px, native height of swoosh: 155px*/
				left: -${(225 - handleSize) / 2 + 4}px;
				top: -${(handleSize * 1.8 - handleSize) / 1.5}px;

				> div {
					height: ${handleSize * 1.8}px!important;
				}
			}
		`;
	};
	return css`
		${(props) =>
			getDefault(
				props,
				props.theme.text.size.bigScreen,
				tickHeight.bigScreen,
				tickSpaceTop.bigScreen,
				tickSpaceBottom.bigScreen,
				handleSize.bigScreen,
				trackHeight.bigScreen,
				handleValueSize.bigScreen,
			)}

		${StyledSliderTickValue} {
			margin-top: ${tickValueMarginTop}px;
			line-height: ${tickValueLineHeight};
			${(props) =>
				fontSize({
					sizeBigScreen: props.theme.text.size.bigScreen,
					important: true,
				})};
		}

		${StyledSliderTick} {
			&:after {
				height: 15px;
			}
		}

		${StyledSliderTrack},
		${StyledSliderRail} {
			height: ${trackHeight.bigScreen}px;
		}

		@media (max-width: ${(props) => props.theme.media.maxWidthDesktop}) {
			${(props) =>
				getDefault(
					props,
					props.theme.text.size.desktop,
					tickHeight.desktop,
					tickSpaceTop.desktop,
					tickSpaceBottom.desktop,
					handleSize.desktop,
					trackHeight.bigScreen,
					handleValueSize.desktop,
				)}

			${StyledSliderTick} {
				&:after {
					height: 10px;
				}
			}
		}

		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			${(props) =>
				getDefault(
					props,
					props.theme.text.size.default,
					tickHeight.desktop,
					tickSpaceTop.tablet,
					tickSpaceBottom.tablet,
					handleSize.tablet,
					trackHeight.default,
					handleValueSize.tablet,
				)}

			${StyledSliderTrack},
			${StyledSliderRail} {
				height: ${trackHeight.default}px;
			}
		}

		@media (max-height: 550px) {
			${(props) =>
				getDefault(
					props,
					props.theme.text.size.default,
					tickHeight.height550,
					tickSpaceTop.height550,
					tickSpaceBottom.height550,
					handleSize.height550,
					trackHeight.default,
					handleValueSize.tablet,
				)}
		}
	`;
};

export const StyledSliderWrapper = styled(Slider)<{
	$rangeMode?: SliderRangeMode;
	$switchAnimation?: boolean;
	$handlePosition?: number;
}>`
	height: 110px;
	margin: auto;
	position: relative;
	${SliderStyles()}

	${StyledSliderTrack} {
		background-color: ${(props) =>
			props.$rangeMode === SliderRangeMode.DEFAULT
				? props.theme.colors.negative
				: props.theme.colors.positive};

		&:nth-of-type(even) {
			background-color: unset;
		}

		&:before {
			content: '';
		}
	}

	${StyledSliderRail} {
		background-color: ${(props) =>
			props.$rangeMode === SliderRangeMode.DEFAULT
				? props.theme.colors.positive
				: props.theme.colors.negative};

		&:before {
			content: '';
		}
	}

	${(props) =>
		props.$switchAnimation
			? css`
					${StyledSliderHandle} {
						animation: ${HandleRotate} 0.2s ease-in-out 1;
					}

					${props.$rangeMode === SliderRangeMode.DEFAULT
						? StyledSliderRail
						: StyledSliderTrack} {
						animation: ${BarSwitchAnimation} 0.25s ease-in-out 1;
						overflow: hidden;

						&:before {
							animation-duration: 0.25s;
							animation-fill-mode: forwards;
							animation-iteration-count: 1;
							animation-name: ${BarSwitchSlideAnimation};
							animation-timing-function: ease-in-out;
							background-color: #fff;
							box-shadow: 0 0 15px 15px #fff;
							display: block;
							height: 100%;
							left: ${props.$rangeMode === SliderRangeMode.DEFAULT
								? `${props.$handlePosition}%`
								: '0'};
							position: absolute;
							transform: rotateZ(
								${props.$rangeMode === SliderRangeMode.DEFAULT ? '-' : ''}45deg
							);
							width: 0;
						}
					}
			  `
			: ''};

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		${(props) =>
			props.$rangeMode === SliderRangeMode.DEFAULT
				? css`
						${StyledSliderRail} {
							background-color: ${props.theme.colors.positiveLight};
						}
				  `
				: css`
						${StyledSliderTrack} {
							background-color: ${props.theme.colors.positiveLight};
						}
				  `};
	}
`;

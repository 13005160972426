import styled from 'styled-components';
import { borderRadius } from '@legacyApp/client/modules/style/defaults';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';
import { StyledLottie } from '@common/components/LottieWrapper/Lottie.styled';
import { LottieIds } from '@common/components/LottieWrapper/Lottie.ids';

export const gameSliderResultTransition = 150;

export const StyledGameSliderResultBox = styled.div.attrs(() => ({
	className: 'game-slider--box',
}))`
	float: left;
	width: 97.7%;
	margin-left: 1.15%;
	margin-right: 1.15%;
	height: 1px;
	position: relative;
	z-index: 3;
	will-change: transform;
	opacity: ${(props) => (props.$show ? '1' : '0')};
	transition: ${(props) =>
		props.$disable
			? 'none'
			: `transform ${gameSliderResultTransition}ms ease-out 0s!important`};

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		width: calc(100% - 10px);
		margin-left: 0;
		margin-right: 0;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
		width: calc(100% - 13.5px);
		margin-left: -3px;
		margin-right: -3px;
	}
`;

export const StyledGameSliderResult = styled.div.attrs(() => ({
	className: 'game-slider-result',
}))`
	position: absolute;
	${(props) =>
		fontSize({
			sizeBigScreen: props.theme.text.size.bigScreen,
			important: true,
		})};
	background: #fff;
	color: ${(props) =>
		props.$result === 'win'
			? props.theme.colors.positiveLight
			: props.$result === 'loss'
			? props.theme.colors.negative
			: '#000'};
	padding: 10px 0;
	text-align: center;
	${borderRadius};
	width: 50px;
	transform: translateX(-25px);
	/*box-shadow: 0 0 17px 0 #fff;*/
	top: 20px;

	&:after {
		content: '';
		display: block;
		position: absolute;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #fff;
		left: 50%;
		bottom: -7px;
		transform: translate3d(-50%, 0, 0);
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		top: 18px;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
		top: 29px;
	}

	@media (max-height: 550px) {
		top: 11px;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		animation-timing-function: linear;
		height: ${(props) => props.theme.layout.input.heightDesktop};
	}

	@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
		width: 70px;
		height: ${(props) => props.theme.layout.input.heightBigDesktop};
	}
`;

export const StyledGameSliderResultStarsBox = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 3;

	> div {
		position: absolute;
	}

	${StyledLottie}[dataname="${LottieIds.GAME_RESULT_STAR_1}"] {
		right: -24px;
		top: -25px;
	}

	${StyledLottie}[dataname="${LottieIds.GAME_RESULT_STAR_2}"] {
		left: -17px;
		top: 15px;
	}
`;

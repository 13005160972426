import React, {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import ReactCompoundSlider, {
	Handles,
	Rail,
	Ticks,
	Tracks,
} from 'react-compound-slider';
import { TracksObject } from 'react-compound-slider/dist/types/Tracks/types';
import { RepeatAnimationClass } from '@legacyApp/client/modules/app/animationService';
import { isFunction } from '@common/methods/isFunction';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';
import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { Sounds } from '@common/constants/config/sounds';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import transactionService from '../../../../modules/transactions/transactionsService';
import audioService from '../../../../LegacyApp/client/modules/app/audioService';
import { Handle } from './handle';
import { Track } from './track';
import { Tick } from './tick';
import {
	StyledSliderRail,
	StyledSliderTicks,
	StyledSliderWrapper,
} from './Slider.styled';
import { SWOOSH_DURATION } from './SwitchSwoosh';
import { getChangedValueIndexFromArray } from './methods/getChangedValueIndexFromArray';

let LAST_SLIDER_SOUND_VALUE: number = null;
let SLIDER_ACTIVE: string = null;
const SLIDER_MARGIN = 1;

export enum SliderRangeMode {
	DEFAULT = 0,
	OPPOSITE = 1,
	NO_RANGE = 2,
}

export interface SliderProps {
	minDisplay: number;
	maxDisplay: number;
	values: ReadonlyArray<number>;
	step: number;
	ticks: number | Array<number>;
	rangeMode: SliderRangeMode;
	soundActive: boolean;
	onChange: (values: ReadonlyArray<number>, index: number) => void;
	disableHandleValue?: boolean;
	railChildren: ReactNode;
	animation: boolean;
	validate?: (
		valuesArray: ReadonlyArray<number>,
		value: number,
		index: number,
	) => number;
	onLockChange?: (isLocked: boolean) => void;
}

export const Slider: FC<SliderProps> = ({
	rangeMode,
	step,
	values,
	minDisplay,
	maxDisplay,
	ticks,
	soundActive,
	onChange,
	disableHandleValue,
	railChildren,
	animation,
	validate,
	onLockChange,
}) => {
	const switchAnimationClass = useRef(
		new RepeatAnimationClass(SWOOSH_DURATION),
	);
	const onChangeTimeout = useRef(null);

	const [activeHandleId, setActiveHandleId] = useState<string>(null);
	const [rootWidth, setRootWidth] = useState('unset');
	const [rootMarginLeft, setRootMarginLeft] = useState('unset');
	const [railWidth, setRailWidth] = useState('unset');
	const [railMarginLeft, setRailMarginLeft] = useState('unset');
	const [mode] = useState<ReactCompoundSlider.SliderProps['mode']>(2);
	const [metrics, setMetrics] = useState([]);
	const [sliderMargin, setSliderMargin] = useState(SLIDER_MARGIN);
	const [values_, setValues] = useState<ReadonlyArray<number>>([]);
	const [domain, setDomain] = useState([minDisplay, maxDisplay]);
	const [switchAnimation, setSwitchAnimation] = useState(false);

	const setDomain_ = useCallback(() => {
		setDomain([minDisplay, maxDisplay]);
	}, [minDisplay, maxDisplay]);

	const setValues_ = useCallback(() => {
		setValues(values);
	}, [values]);

	const handleSwitchEffect = useCallback(() => {
		if (animation) {
			switchAnimationClass.current.trigger(switchAnimation, setSwitchAnimation);
		}
		audioService.play(Sounds.switch, undefined, 0.15);
	}, [animation, switchAnimation]);

	const setMetrics_ = useCallback(() => {
		if (typeof ticks === 'number') {
			const metrics = [minDisplay];
			const diff = (maxDisplay - minDisplay) / (ticks - 1);
			for (let i = 0; i <= ticks - 1; i++) {
				metrics.push(metrics[metrics.length - 1] + diff);
			}
			setMetrics(metrics);
			return;
		}
		setMetrics(ticks);
	}, [maxDisplay, minDisplay, ticks]);

	const setSliderMargin_ = useCallback(() => {
		if (typeof minDisplay === 'number' && typeof maxDisplay === 'number') {
			const margin =
				SLIDER_MARGIN +
				roundNumber(
					((maxDisplay - minDisplay) / (maxDisplay - minDisplay) - 1) * 50,
					4,
				);
			setSliderMargin(margin);
		}
	}, [maxDisplay, minDisplay]);

	const setRootWidth_ = useCallback(() => {
		setRootWidth(`${100 - 2 * sliderMargin}%`);
	}, [sliderMargin]);

	const setRailWidth_ = useCallback(() => {
		setRailWidth(`${100 + 2 * sliderMargin}%`);
	}, [sliderMargin]);

	const setRailMarginLeft_ = useCallback(() => {
		setRailMarginLeft(`${-sliderMargin}%`);
	}, [sliderMargin]);

	const setRootMarginLeft_ = useCallback(() => {
		setRootMarginLeft(`${sliderMargin}%`);
	}, [sliderMargin]);

	const validate_: SliderProps['validate'] = useCallback(
		(valuesArray, value, index) => {
			if (isFunction(validate)) {
				return validate(valuesArray, value, index);
			}
			return value;
		},
		[validate],
	);

	const onChange_ = useCallback(
		(values: ReadonlyArray<number>, indexOfChangedValue: number) => {
			// console.log('onChange_ - 1',values);
			setValues(values);
			if (onChangeTimeout.current) {
				clearTimeout(onChangeTimeout.current);
			}
			onChangeTimeout.current = setTimeout(() => {
				const newValues = [...values];
				newValues[indexOfChangedValue] = validate_(
					newValues,
					newValues[indexOfChangedValue],
					indexOfChangedValue,
				);
				// console.log('onChange_ - 2', indexOfChangedValue, values, newValues);
				setValues(newValues);
				if (isFunction(onChange)) {
					onChange(newValues, indexOfChangedValue);
				}
			}, 100);
		},
		[onChange, validate_],
	);

	const onUpdate = useCallback(
		(valuesArray: ReadonlyArray<number>, force?: boolean) => {
			const precision = countPrecision(step);

			const newValues = valuesArray
				.filter((value) => isTrueOrZero(value))
				.map((value) => roundNumber(value, precision));
			const roundedValues_ = values_.map((value) =>
				roundNumber(value, precision),
			);

			if (
				(!SLIDER_ACTIVE ||
					!transactionService.isListDifferent(newValues, roundedValues_)) &&
				!force
			) {
				return;
			}

			const result = getChangedValueIndexFromArray(newValues, roundedValues_);

			const indexOfChangedValue = result?.[0]?.index;

			if (!isTrueOrZero(indexOfChangedValue)) {
				return;
			}

			const values = [...values_];
			values[indexOfChangedValue] = valuesArray[indexOfChangedValue];

			onChange_(values, indexOfChangedValue);

			const value = newValues[indexOfChangedValue];

			if (value === LAST_SLIDER_SOUND_VALUE) {
				return;
			}
			LAST_SLIDER_SOUND_VALUE = value;
			if (soundActive) {
				audioService.play(Sounds.slider);
			}
		},
		[onChange_, soundActive, step, values_],
	);

	const onStart: ReactCompoundSlider.SliderProps['onSlideStart'] = useCallback(
		(values, data) => {
			SLIDER_ACTIVE = data.activeHandleID;
			if (isFunction(onLockChange)) {
				onLockChange(true);
			}
			console.log('SLIDER - onStart', values, data, SLIDER_ACTIVE);
			if (disableHandleValue) {
				return;
			}
			setActiveHandleId(data.activeHandleID);
		},
		[disableHandleValue, onLockChange],
	);

	const onEnd: ReactCompoundSlider.SliderProps['onSlideEnd'] = useCallback(
		(valuesArray) => {
			console.log('SLIDER - onEnd', valuesArray, values_, SLIDER_ACTIVE);
			if (transactionService.isListDifferent(values_, valuesArray)) {
				onUpdate(valuesArray, true);
			}
			SLIDER_ACTIVE = null;
			if (isFunction(onLockChange)) {
				onLockChange(false);
			}
			setActiveHandleId(null);
		},
		[onLockChange, onUpdate, values_],
	);

	const getRailChildren = useCallback(
		({ getRailProps }: ReactCompoundSlider.RailObject) => (
			<StyledSliderRail
				style={{
					width: railWidth,
					marginLeft: railMarginLeft,
				}}
				{...getRailProps()}
			>
				{railChildren}
			</StyledSliderRail>
		),
		[railChildren, railMarginLeft, railWidth],
	);

	const handlesChildren = useCallback(
		({ handles, getHandleProps }: ReactCompoundSlider.HandlesObject) => (
			<div>
				{handles.map((handle: ReactCompoundSlider.SliderItem) => (
					<Handle
						animation={switchAnimation}
						hideValue={activeHandleId !== handle.id || disableHandleValue}
						key={handle.id}
						id={handle.id}
						percent={handle.percent}
						value={handle.value}
						getHandleProps={getHandleProps}
					/>
				))}
			</div>
		),
		[disableHandleValue, activeHandleId, switchAnimation],
	);

	const tracksChildren = useCallback(
		({ tracks, getTrackProps }: TracksObject) => (
			<div>
				{tracks.map(({ id, source, target }: ReactCompoundSlider.TrackItem) => (
					<Track
						key={id}
						sourcePercent={source.percent}
						targetPercent={target.percent}
						sliderMargin={sliderMargin}
						getTrackProps={getTrackProps}
					/>
				))}
			</div>
		),
		[sliderMargin],
	);

	const ticksChildren = useCallback(
		({ ticks }: ReactCompoundSlider.TicksObject) => (
			// get back ids, values and percents (to place them)
			<StyledSliderTicks>
				{ticks.map((tick) => (
					<Tick key={tick.id} percent={tick.percent} value={tick.value} />
				))}
			</StyledSliderTicks>
		),
		[],
	);

	useEffect(() => {
		handleSwitchEffect();
	}, [rangeMode]);

	useEffect(() => {
		setRootWidth_();
		setRootMarginLeft_();
		setRailWidth_();
		setRailMarginLeft_();
	}, [setRailMarginLeft_, setRailWidth_, setRootMarginLeft_, setRootWidth_]);

	useEffect(() => {
		setSliderMargin_();
	}, [setSliderMargin_]);

	useEffect(() => {
		setDomain_();
	}, [setDomain_]);

	useEffect(() => {
		setMetrics_();
	}, [setMetrics_]);

	useEffect(() => {
		setValues_();
	}, [setValues_]);

	return (
		<StyledSliderWrapper
			$rangeMode={rangeMode}
			$switchAnimation={switchAnimation}
			$handlePosition={values?.[0]}
			mode={mode}
			step={step}
			domain={domain}
			rootStyle={{
				width: rootWidth,
				marginLeft: rootMarginLeft,
			}}
			values={values_}
			onUpdate={onUpdate}
			onSlideStart={onStart}
			onSlideEnd={onEnd}
		>
			<Rail>{getRailChildren}</Rail>
			{!!values && <Handles>{handlesChildren}</Handles>}
			{rangeMode !== SliderRangeMode.NO_RANGE && rangeMode !== undefined && (
				<Tracks left={false}>{tracksChildren}</Tracks>
			)}
			{metrics && <Ticks values={metrics}>{ticksChildren}</Ticks>}
		</StyledSliderWrapper>
	);
};

import styled, { css } from 'styled-components';
import { noclick } from '@legacyApp/client/modules/style/defaults';
import {
	StyledSliderRail,
	StyledSliderTick,
	StyledSliderTickValue,
	StyledSliderTrack,
	StyledSliderWrapper,
} from '@common/components/games/Slider/Slider.styled';
import {
	StyledSliderHandle,
	StyledSliderHandleWrapper,
} from '@common/components/games/Slider/handle';
import { dashboard_v2 } from '@legacyApp/client/modules/style/mixins/dashboard';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { StyledGameSliderResult } from '../GameSliderResult/GameSliderResult.styled';

export interface StyledGameSliderProps {
	$disable?: boolean;
	$demo?: boolean;
	$isMobile?: boolean;
	className?: string;
}

const demo = (props) => css`
	overflow: visible;
	padding-bottom: 15px !important;

	${StyledGameSliderResult} {
		top: -13px !important;

		@media (max-width: ${props.theme.media.maxWidthTablet}) {
			top: 5px !important;
		}

		@media (min-width: ${props.theme.media
				.minWidthDesktop}) and (max-width: ${props.theme.media
				.maxWidthDesktop}) {
			top: -4px !important;
		}
	}

	${StyledSliderWrapper} {
		height: 80px;
	}

	${StyledSliderRail},
	${StyledSliderTrack} {
		margin-top: 50px;
	}

	${StyledSliderHandleWrapper} {
		margin-left: -20.5px;
		margin-top: 35px;
	}

	${StyledSliderHandle} {
		height: 40px;
		width: 40px;
	}

	${StyledSliderTick} {
		background-color: #272f3c !important;
		height: 20px;
		margin-top: 23px;

		&:after {
			background-color: #6b778d !important;
			height: 8px;
		}
	}

	${StyledSliderTickValue} {
		color: #4272b4 !important;
		margin-top: 0;
	}
`;

export const StyledGameSlider = styled.div.attrs<StyledGameSliderProps>({
	className: 'game-slider',
})<StyledGameSliderProps>`
	float: left;
	position: relative;
	overflow: hidden;
	padding-bottom: 50px;
	margin-bottom: -50px;
	padding-top: 40px;
	margin-top: -50px;

	${(props) =>
		props.$disable
			? css`
					${noclick}
					${StyledSliderTrack},
 	  ${StyledSliderRail} {
						transition: none;
					}
			  `
			: ''}

	${(props) =>
		props.$demo
			? demo(props)
			: css`
					${dashboard_v2(
						(padding) => css`
							padding-left: ${padding};
							padding-right: ${padding};

							${chatViewport(
								(viewportPadding) => css`
									@media (max-width: ${(props) =>
											calcStyle({
												a: props.theme.media.maxWidthDesktop,
												b: viewportPadding,
											})}) {
										width: calc(100% + ((5px + (${padding} / 2)) * 2));
										margin-left: calc(((5px + (${padding} / 2)) * -1));
									}

									@media (min-width: ${(props) =>
											calcStyle({
												a: props.theme.media.minWidthBigDesktop,
												b: viewportPadding,
											})}) {
										width: calc(100% + ((25px + (${padding} / 2)) * 2));
										margin-left: calc(((25px + (${padding} / 2)) * -1));
									}

									@media (min-width: ${(props) =>
											props.theme.media.minWidthDesktop}) and (max-width: ${(
											props,
										) =>
											calcStyle({
												a: props.theme.media.maxWidthTablet,
												b: viewportPadding,
											})}) {
										width: calc(100% + 90px); // 60 + 30
										margin-left: -45px; // 60 - 15
										padding-right: 60px;
										padding-left: 60px;
									}

									@media (max-width: ${(props) =>
											props.theme.media.maxWidthTablet}) {
										width: 100vw;
										margin-left: -${padding};
									}
								`,
							)}
						`,
					)}
			  `}
`;
